import React from "react";
import * as basicScroll from "basicscroll";
import { Button, Image } from "react-bootstrap";

const MainBanner = (props) => {
    const { t, frontURL, onChangeRoute, dirSize } = props;

    document.querySelectorAll(".scene").forEach((elem) => {
        const modifier = elem.getAttribute("data-modifier");

        basicScroll
            .create({
                elem: elem,
                from: 0,
                to: 800,
                direct: true,
                props: {
                    "--translateY": {
                        from: "0",
                        to: `-${10 * modifier}px`,
                    },
                },
            })
            .start();
    });

    return (
        <div>
            <div id="fixedBlackBg"></div>
            <div id="mainBanner">
                {dirSize.includes("xs") || dirSize.includes("sm") || dirSize.includes("md") ? (
                    <Image src={`${frontURL}/images/main-banner/${dirSize}/main_banner.jpg`} alt="main_banner" />
                ) : (
                    <>
                        <div className="scene" id="bg0" data-modifier="0" />
                        <img className="scene" src={`${frontURL}/images/main-banner/${dirSize}/9.webp`} data-modifier="2" alt="main banner layer 1" />
                        <img className="scene" src={`${frontURL}/images/main-banner/${dirSize}/8.webp`} data-modifier="4" alt="main banner layer 2" />
                        <img className="scene" src={`${frontURL}/images/main-banner/${dirSize}/7.webp`} data-modifier="8" alt="main banner layer 3" />
                        <img className="scene" src={`${frontURL}/images/main-banner/${dirSize}/6.webp`} data-modifier="16" alt="main banner layer 4" />
                        <img className="scene" src={`${frontURL}/images/main-banner/${dirSize}/5.webp`} data-modifier="90" id="rocket" alt="main banner layer 5" />
                        <img className="scene" src={`${frontURL}/images/main-banner/${dirSize}/4.webp`} data-modifier="26" alt="main banner layer 6" />
                        <img className="scene" src={`${frontURL}/images/main-banner/${dirSize}/3.webp`} data-modifier="32" alt="main banner layer 7" />
                        <img className="scene" src={`${frontURL}/images/main-banner/${dirSize}/2.webp`} data-modifier="40" alt="main banner layer 8" />
                        <img className="scene" src={`${frontURL}/images/main-banner/${dirSize}/1.webp`} data-modifier="50" alt="main banner layer 9" />
                        <div className="scene" id="bgCover" data-modifier="52" />
                    </>
                )}
            </div>
            <div id="mainBannerBg" className="d-lg-none"></div>
            <div id="mainBannerTexts">
                <Image src={`${frontURL}/images/lyzkon_logo_white.svg`} alt="lyzkon_logo_white" id="mainBannerLogo" />
                <h1 id="mainBannerTitle">
                    07-13.07.2025
                    <br />
                    {t("home.mainBanner.title")}
                </h1>
                <h2 id="mainBannerSubtitle">{t("home.mainBanner.subtitle")}</h2>
                <Button id="mainBannerBtn" variant="primary" onClick={(e) => onChangeRoute(e, `/${t("url.link.myAccount")}/${t("url.link.accreditation")}/${t("url.link.accrForm")}`)}>
                    {t("home.mainBanner.btn")}!
                </Button>
            </div>
            <div id="scrollDown" style={{ display: "none" }}>
                <div>
                    <div id="scrollDownBall"></div>
                </div>
            </div>
        </div>
    );
};

export default MainBanner;
